<template>
  <div>
    <!--begin::Content header-->
    <!-- <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
    >
      <span class="font-weight-bold font-size-3 text-dark-60">
        Don't have an account yet?
      </span>
      <router-link
        class="font-weight-bold font-size-3 ml-2"
        :to="{ name: 'register' }"
      >
        Sign Up!
      </router-link>
    </div> -->
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your Email and password
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div role="alert" class="alert alert-info" style="opacity: 0;">
          <div class="alert-text">
            Use account <strong>superadmin@gmail.com</strong> and password
            <strong>secret</strong> to continue.
          </div>
        </div>

        <div
          class="card-header cardHeader-bg"
          style="padding: 14px;"
          v-if="alert"
        >
          <div
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
          >
            {{ alertText }}
          </div>
        </div>

        <div
          v-if="customAlert"
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>

        <b-form-group
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="email"
            required=""
            id="example-input-1"
            name="example-input-1"
            placeholder="Enter Email Here"
            v-model="email"
            @keyup="isEmailValid"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            required=""
            id="example-input-2"
            name="example-input-2"
            placeholder="Enter Password Here"
            v-model="password"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            href="#"
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Forgot Password ?
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-info  font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Sign In
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      changeClasss: false,
      alertText: "",
      alert: false,
      customAlert: false,
      // Remove this dummy login info
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    isEmailValid: function isEmailValid() {
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = "Please enter valid email address";
        this.changeClasss = false;
        this.alert = true;
      }
      return responce;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      if (this.password.length > 0 && this.password.length < 6) {
        this.validationMessage("Password must be atleast 6 character long");
        return false;
      } else {
        this.alert = false;
      }
      if (this.alert) {
        return false;
      }
      this.customAlert = true;
      const email = this.email;
      const password = this.password;
      this.alert = false;

      // clear existing errors
      this.$store.dispatch(LOGOUT);
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => {
            JSON.parse(localStorage.getItem("currentUser"));
            this.$router.push({ name: "dashboard" });
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style scoped>
.alert-info {
  background-color: #5cb700;
  border-color: #5cb700;
  color: #ffffff;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
